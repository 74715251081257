// web.ts
// entry point for web component
import * as Common from '@brainfish-ai/widgets-common';
import { Config, CallbackAction, Action } from '@brainfish-ai/widgets-common';

const API_HOST = import.meta.env.VITE_API_HOST;

function mapActionButtons(
  actionButtons: (CallbackAction | Action)[]
): (CallbackAction | Action)[] {
  return actionButtons.map((action) => {
    if (action.type === Common.ActionType.CALLBACK) {
      return {
        ...action,
        value: new Function(`return ${action.value}`)(),
      };
    }
    return action;
  });
}

const transformConfig = ({
  config,
  apiKey,
}: {
  config: Config;
  apiKey: string;
}) => {
  if (!!config.settings?.bodyActionButtons) {
    config.settings.bodyActionButtons = mapActionButtons(
      config.settings.bodyActionButtons
    );
  }
  if (!!config.settings?.footerActionButtons) {
    config.settings.footerActionButtons = mapActionButtons(
      config.settings.footerActionButtons
    );
  }
  if (!!config.settings?.nextBestActions) {
    config.settings.nextBestActions = mapActionButtons(
      config.settings.nextBestActions
    );
  }
  return {
    ...config,
    apiHost: `${API_HOST}`,
    widgetMode: config.widgetType,
    apiKey,
  };
};

const init = async ({ widgetKey }: { widgetKey: string }) => {
  const endpoint = `${API_HOST}/api/searchWidgets.getConfigByKey`;

  const configResponse = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': widgetKey,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log('Error loading widget config', error));

  if (!configResponse) {
    console.log('Error loading widget config');
    return;
  }

  const config = transformConfig({
    config: configResponse.config,
    apiKey: widgetKey,
  });
  const version = config.version || 'latest';

  // import the widget based on the version from cdn.jsdelivr.net
  const { default: widget } = await import(
    `https://cdn.jsdelivr.net/npm/@brainfish-ai/search-widget@${version}/dist/web.js`
  );

  switch (config.widgetType) {
    case Common.WidgetType.Searchbar:
    case 'Search':
      widget.SearchWidget.initStandard(config);
      break;
    case Common.WidgetType.Sidebar:
    case 'slide-over': // for backwards compatibility
      widget.HelpWidget.initPopup(config);
      break;
    default:
      return;
  }
};

const brainfish = {
  Widgets: { init }
}

if (brainfish) {
  Common.injectBrainfishInWindow(brainfish);
}

export default brainfish;
